import { isValid, format } from "date-fns"
import Cookies from 'universal-cookie'
import { userData, userSession } from './storage'
import dayjs from "dayjs"
import { cpfCnpjMask } from "./strings"


export const isTv = () => {

  let operatingSystem = 'Not known'

  if (window.navigator.userAgent.indexOf('TV') !== -1) {
    operatingSystem = 'TV'
  } else if (window.navigator.userAgent.indexOf('Win') !== -1) {
    operatingSystem = 'Windows OS'
  } else if (window.navigator.userAgent.indexOf('Mac') !== -1) {
    operatingSystem = 'MacOS'
  } else if (window.navigator.userAgent.indexOf('X11') !== -1) {
    operatingSystem = 'UNIX OS'
  } else if (window.navigator.userAgent.indexOf('Linux') !== -1) {
    operatingSystem = 'Linux OS'
  }

  return operatingSystem === 'TV'
}

export const storageItem = (
  type,
  name,
  value,
  useCookies = false,
  expireCookieDate = new Date((new Date()).getTime() + (86400000 * 365)) // 1 ano
) => {
  const cookies = new Cookies()

  let toReturn

  if (isTv() || useCookies) {
    if (type === 'set') {
      cookies.set(name, JSON.stringify(value), {path: '/', expires: expireCookieDate})
    } else if (type === 'get') {
      toReturn = cookies.get(name)
    } else if (type === 'remove') {
      cookies.remove(name, {expires: expireCookieDate})
    }
  } else {
    if (type === 'set') {
      window.localStorage.setItem(name, JSON.stringify(value))
    } else if (type === 'get') {
      if (window.localStorage.getItem(name)) {
        if (typeof window.localStorage.getItem(name) === 'string') {
          try {
            toReturn = JSON.parse(window.localStorage.getItem(name))
          } catch (e) {
            toReturn = (window.localStorage.getItem(name))
          }
        } else {
          toReturn = window.localStorage.getItem(name)
        }
      } else {
        toReturn = window.localStorage.getItem(name)
      }
    } else if (type === 'remove') {
      window.localStorage.removeItem(name)
    }
  }
  // }

  return toReturn
}

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => storageItem('get', userData)
export const getUserData = () => storageItem('get', userData)
export const getUserSession = () => storageItem('get', userSession)

export const clearStorageUser = () => {
  storageItem('remove', userData)
  storageItem('remove', userSession)
}

export const saveLocalStorageUserData = (data, accessToken) => {
  const responseAbility = data?.user?.permissoes?.map(permission => {
    const action = []

    if (permission?.criacao === 1) {
      action.push('create')
    }

    if (permission?.leitura === 1) {
      action.push('read')
    }

    if (permission?.atualizacao === 1) {
      action.push('update')
    }

    if (permission?.delecao === 1) {
      action.push('delete')
    }

    if (permission?.menu === 1) {
      action.push('menu')
    }

    return {
      action,
      subject: permission.permissao?.nome
    }
  })

  const formattedUserData = {
    ...data?.user,
    ability: responseAbility
  }

  storageItem('set', userData, formattedUserData)

  if (accessToken) {
    storageItem('set', userSession, accessToken)
  }

  return responseAbility
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const parseDate = (date, targetFormat = 'dd/MM/yyyy') => {
  if (!date) {
    console.error('You must provide a date value.')
    return
  }

  if (date?.length <= 10) {
    date = date?.replace("-", "/").replace("-", "/").replace("-", "/")
  }
  if (typeof date === "string") {
    date = date?.replace("Z", "")
  }

  if (isValid(date)) {
    return format(date, targetFormat)
  }

  if (isValid(new Date(date))) {
    return format(new Date(date), targetFormat)
  }

  console.error('Invalid date value')
}

export function getBackendDate(frontendDate, time = true) {
  if (!frontendDate || typeof frontendDate !== 'string') {
    console.error('You need to provide a valid date string format')
    return ''
  }

  const dataArr = frontendDate.split('/')

  if (time) {
    return `${dataArr[2]}-${dataArr[1]}-${dataArr[0]} 00:00:00`
  }
  return `${dataArr[2]}-${dataArr[1]}-${dataArr[0]}`
}

export const useQuery = (search) => {
  // const { search } = useLocation();
  return new URLSearchParams(search)
}

export function parseCpf(v) {
  v = v.replace(/\D/g, "")                    //Remove tudo o que não é dígito
  v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
                                           //de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
  return v
}

export function getDaysFromToday(date) {
  return Math.round((new Date() - new Date(date)) / (1000 * 60 * 60 * 24))
}

export const formatToCurrency = (value, locale = 'pt-BR', currency = 'BRL') => {
  const formatting = { style: 'currency', currency }

  return new Intl.NumberFormat(locale, formatting).format(value)
}

export const getMinutesDiff = (dateTimeValue2, dateTimeValue1) => {
  let differenceValue = (dateTimeValue2.getTime() - dateTimeValue1.getTime()) / 1000
  differenceValue /= 60
  return Math.abs(Math.round(differenceValue))
}

export const mockCardUrl = (id, ficha, hideBar) => {
  if (!!id && !!ficha) {
    return `${hideBar ? '' : '/'}cliente?id=${btoa(id)}&id_ficha=${btoa(ficha)}`
  }

  if (!!id) {
    return `${hideBar ? '' : '/'}cliente?id=${btoa(id)}`
  }

  return '#'
}

export const formattedType = (value, formato) => {
  switch (formato) {
    case 'moeda':
      return formatToCurrency(value)
    case 'data':
      const data = dayjs(value).format(`DD/MM/YYYY`) || ''
      if (data === 'Invalid Date') {
        return ''
      } else {
        return data
      }
    case 'texto_simples':
      return value
    case 'numero':
      return value
    case 'porcentagem':
      return `${value} %`
    case 'data_hora':
      return dayjs(value).format(`DD/MM/YYYY HH:mm:ss`)
    case 'duracao':
      return value
    case 'hora':
      return dayjs(value).format(`HH:mm:ss`)
    case 'documento':
      return cpfCnpjMask(value)

    default:
      break
  }
  return value
}
