import { useContext, createContext, useState, useCallback } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { cloneDeep } from 'lodash'
import notificatoin from '../../services/notificatoin'
function compareDate(a, b) {
  const bDate = moment(b.data_criacao)
  const aDate = moment(a.data_criacao)

  return bDate.diff(aDate)
}

const NotificationContext = createContext({})

function NotificationProvider({ children }) {
  const [notificationsArray, setNotificationsArray] = useState([])
  const [notificationCount, setNotificationCount] = useState(0)
  const handleMarkAsRead = useCallback(
    async (id) => {
      const oldList = cloneDeep(notificationsArray)
      const oldNotificationCount = cloneDeep(notificationCount)
      const tempList = cloneDeep(notificationsArray)

      const itemIndex = tempList.findIndex((item) => item.id === id)

      const updatedItem = tempList.splice(itemIndex, 1)[0]

      if (updatedItem.lido === 1) {
        return
      }

      updatedItem.lido = 1

      tempList.push(updatedItem)

      const notRead = tempList.filter((data) => data.lido === 0)
      const read = tempList.filter((data) => data.lido === 1).sort(compareDate)

      setNotificationCount((oldValue) => oldValue - 1)

      setNotificationsArray([...notRead, ...read])

      const response = await notificatoin.readNotification(id)

      if (response.isAxiosError || !response.data.success) {
        toast.error('Erro ao tentar marcar como lida!')
        setNotificationCount(oldNotificationCount)
        setNotificationsArray(oldList)
      }
    },
    [notificationsArray, notificationCount]
  )
  return (
    <NotificationContext.Provider
      value={{
        handleMarkAsRead,
        notificationsArray,
        setNotificationsArray,
        notificationCount,
        setNotificationCount
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

function useNotification() {
  return useContext(NotificationContext)
}

export { NotificationProvider, useNotification }
