import api from './api'

export default {
  getAzureUrl: async () => {
    const response = await api
      .get('/api/login-advisor')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  sendCodeAd: async (data) => {
    const response = await api
      .post('api/login-advisor', data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  user: async (data) => {
    const response = await api
      .get(`/api/user`, data && { params: data })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}