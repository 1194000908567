import { useContext, createContext, useState, useEffect } from 'react'

import { clientsCards } from '../storage'

import { storageItem, getUserData, mockCardUrl, saveLocalStorageUserData } from '@utils'

const HomeContext = createContext({})

import { useSocket } from '@src/utility/hooks/useSocket'

import auth from '@src/services/auth'

import { AbilityContext } from '@src/utility/context/Can'

function HomeProvider({ children }) {
  const ability = useContext(AbilityContext)

  const [pendenteCount, setPendenteCount] = useState(0)
  const [naoRealizadaCount, setNaoRealizadaCount] = useState(0)
  const [realizadaCount, setRealizadaCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [clients, setClients] = useState([])
  const [user, setUser] = useState(getUserData())
  const [loadingUserInfo, setLoadingUserInfo] = useState(true)

  const updateUserInfo = async () => {
    setLoadingUserInfo(true)
    const { data, status } = await auth.user()

    if (status !== 200 || !data) {
      return
    }

    const responseAbility = saveLocalStorageUserData(data, data?.access_token)
    ability.update(responseAbility)

    const formattedUserData = {
      ...data?.user,
      ability: responseAbility
    }

    setUser(formattedUserData)
    setLoadingUserInfo(false)

    return formattedUserData
  }

  useSocket({
    channel: `presence-abrirFicha.${user?.id}`,
    event: 'SendOpenRecord',
    callback: (data) => {
      window.location = mockCardUrl(data?.data?.id_cliente, data?.data?.id, true)
    }
  })

  useEffect(() => {
    const storageClients = storageItem('get', clientsCards)

    if (!!storageClients) {
      setClients(JSON.parse(storageClients))
    }
  }, [])

  const handleSelectClient = (client) => {
    let toStorage = []
    setClients(prev => {
      const index = prev.findIndex(item => item.id === client.id)
      if (index > -1) {
        prev[index] = client
        toStorage = prev
        return prev
      }
      toStorage = ([client, ...prev])
      return ([client, ...prev])
    })

    if (toStorage.length > 0) {
      storageItem('set', clientsCards, JSON.stringify(toStorage))
    }
  }

  const handleCloseClient = (client) => {
    let toStorage = []
    setClients(prev => {
      const temp = prev.filter(item => item.id !== client.id)

      toStorage = temp

      return temp
    })

    if (toStorage.length > 0) {
      storageItem('set', clientsCards, JSON.stringify(toStorage))
    } else {
      storageItem('remove', clientsCards)
    }
  }

  return (
    <HomeContext.Provider value={{
      pendenteCount,
      setPendenteCount,
      naoRealizadaCount,
      setNaoRealizadaCount,
      realizadaCount,
      setRealizadaCount,
      totalCount,
      setTotalCount,
      handleSelectClient,
      handleCloseClient,
      clients,
      user,
      setUser,
      loadingUserInfo,
      setLoadingUserInfo,
      updateUserInfo
    }}>
      {children}
    </HomeContext.Provider>
  )
}

function useHome() {
  return useContext(HomeContext)
}

export { HomeProvider, useHome }
