import { useContext, createContext, useState } from 'react'
import { useHome } from './useHome'

const DiscadorContext = createContext({})

function DiscadorProvider({ children }) {
  const { user } = useHome()

  const [onJourney, setOnJourney] = useState(user?.jornada_iniciada === 1)

  return (
    <DiscadorContext.Provider value={{ onJourney, setOnJourney }}>
      {children}
    </DiscadorContext.Provider>
  )
}

function useDiscador() {
  return useContext(DiscadorContext)
}

export { DiscadorProvider, useDiscador }
