import Pusher from 'pusher-js'

window.Pusher = Pusher

export function createSocketConnection(token) {
  // if (window.location.origin === process.env.REACT_APP_LIBERTA_URL) {
  //   return
  // }
  if (!window.PusherEcho) {
    window.PusherEcho = new Pusher(process.env.REACT_APP_SOCKET_KEY, {
      wsHost: process.env.REACT_APP_WS_HOST,
      wsPort: process.env.REACT_APP_WS_PORT,
      wssPort: process.env.REACT_APP_WS_PORT,
      authEndpoint: `${process.env.REACT_APP_API_URL}/api/broadcasting/auth`,
      forceTLS: true,
      enabledTransports: ['ws', 'wss'],
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json"
        }
      }
    })
  }
}

export function createChatSocketConnection(token) {
  // if (window.location.origin === process.env.REACT_APP_LIBERTA_URL) {
  //   return
  // }
  if (!window.PusherEchoChat) {
    window.PusherEchoChat = new Pusher(process.env.REACT_APP_SOCKET_KEY, {
      wsHost: process.env.REACT_APP_CHAT_HOST,
      wsPort: process.env.REACT_APP_CHAT_PORT,
      wssPort: process.env.REACT_APP_CHAT_PORT,
      authEndpoint: `${process.env.REACT_APP_CHAT_API_URL}/api/broadcasting/auth`,
      forceTLS: true,
      enabledTransports: ['ws', 'wss'],
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json"
        }
      }
    })
  }
}
