import { useEffect } from 'react'

import { createSocketConnection } from '../../services/socketService'

import { getUserSession } from '../Utils'

function listen(
  pusherChannel,
  callback = () => {},
  channel,
  event,
  callbackUser = () => {}
) {
  pusherChannel?.bind('pusher:subscription_succeeded', () => {})

  pusherChannel?.bind('pusher:subscription_error', () => {})

  pusherChannel?.bind('pusher:member_added', callbackUser)
  pusherChannel?.bind('pusher:member_removed', callbackUser)

  pusherChannel?.bind(event, callback)

  return function cleanUp() {
    window.PusherEcho.unsubscribe(channel)
  }
}

export const useSocket = ({ callback, channel, event, callbackUser }) => {
  const token = getUserSession()
  let pusherChannel = window.PusherEcho?.subscribe(channel)

  useEffect(() => {
    createSocketConnection(token)

    pusherChannel = window.PusherEcho?.subscribe(channel)

    if (pusherChannel !== undefined) {
      return listen(pusherChannel, callback, channel, event, callbackUser)
    }
  }, [window.PusherEcho])

  return { pusherChannel }
}
