import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap'
import { useRolloverNotification } from '../../utility/hooks/useRolloverNotification'
import { useEffect, useState } from 'react'
import notificatoin from '../../services/notificatoin'
import { ToastError } from '../../utility/ToastError'
import { toast } from 'react-toastify'
import { useNotification } from '../../utility/hooks/useNotification'
import select from '../../services/select'

export const ModalRolloverNotification = () => {
  const [input, setInput] = useState('')
  const [optionsSelect, setOptionsSelect] = useState([])
  const [optionsSelectLoading, setOptionsSelectLoading] = useState(false)
  const [invalidInput, setInvalidInput] = useState(false)
  const { setNotificationsArray } = useNotification()
  const {
    openRolloverNotification,
    handleCloseRolloverNotification,
    idRolloverNotification
  } = useRolloverNotification()

  const { handleMarkAsRead } = useNotification()
  const handleAccepct = async () => {
    if (!input) {
      return setInvalidInput(true)
    } else {
      setInvalidInput(false)
    }
    const response = await notificatoin.recuseRoulette({
      motivo: input === 0 ? null : input,
      id_notificacao: idRolloverNotification?.id,
      id_atividade: idRolloverNotification?.id_atividade
    })

    if (response.status === 200) {
      toast.success(response.data.message)
      handleMarkAsRead(idRolloverNotification?.id)
      setNotificationsArray((old) =>
        old.map((notification) => {
          if (notification.id === idRolloverNotification?.id) {
            notification.status = 2
          }
          return notification
        })
      )
      handleCloseRolloverNotification()
    } else {
      new ToastError(response).showToastError()
    }
  }
  const getReasonRefusal = async () => {
    setOptionsSelectLoading(true)
    const response = await select.getReasonRefusal(
      idRolloverNotification.id_fila
    )
    if (response.status === 200) {
      setOptionsSelect(response.data)
    }
    setOptionsSelectLoading(false)
  }
  useEffect(() => {
    if (!!idRolloverNotification) {
      getReasonRefusal()
    }
  }, [idRolloverNotification])
  return (
    <Modal
      centered
      isOpen={openRolloverNotification}
      toggle={handleCloseRolloverNotification}
    >
      <ModalHeader>Motivo para recusa</ModalHeader>
      <ModalBody>
        <section className="d-flex">
          <p className="font-weight-bolder">Preencha</p>
        </section>

        <div
          style={{
            gap: '8px',
            display: 'grid'
          }}
        >
          {optionsSelectLoading ? (
            <aside className="d-flex justify-content-center align-items-center">
              <Spinner color="primary" />
            </aside>
          ) : (
            <FormGroup>
              <Label for="exampleText">Motivo</Label>

              {optionsSelect.length > 0 ? (
                <Input
                  id="exampleText"
                  onChange={(e) => setInput(e.target.value)}
                  name="select"
                  invalid={invalidInput}
                  type="select"
                  onBlur={(eee) => setInvalidInput(!eee.target.value)}
                >
                  <option value={0}>Selcione uma opção</option>
                  {optionsSelect.map((item) => (
                    <option key={item.id} value={item.descricao}>
                      {item.descricao}
                    </option>
                  ))}
                </Input>
              ) : (
                <Input
                  value={input}
                  invalid={invalidInput}
                  onBlur={() => setInvalidInput(!input)}
                  onChange={(e) => setInput(e.target.value)}
                  id="exampleText"
                  name="text"
                  type={'textarea'}
                />
              )}
            </FormGroup>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button outline onClick={() => handleCloseRolloverNotification()}>
          Cancelar
        </Button>
        <Button onClick={() => handleAccepct()} color="primary">
          Salvar
        </Button>
      </ModalFooter>
    </Modal>
  )
}
