import { toast } from 'react-toastify'

export class ToastError {
  constructor(response) {
    this.response = response
  }
  showToastError() {
    if (this.response?.isAxiosError) {
      const responseData = this.response?.response.data
      if (responseData instanceof Array) {
        return toast.error(responseData[0])
      }
      if (typeof responseData?.error === 'string') {
        return toast.error(responseData?.error)
      }
      if (responseData instanceof Object) {
        return toast.error(Object.values(responseData).flat(Infinity)[0])
      }
      return toast.error(responseData)
    }

    const responseData = this.response.data
    if (responseData instanceof Array) {
      return toast.error(responseData[0])
    }
    if (typeof responseData?.error === 'string') {
      return toast.error(responseData?.error)
    }
    if (responseData instanceof Object) {
      return toast.error(Object.values(responseData).flat(Infinity)[0])
    }
    return toast.error(responseData)
  }
}
