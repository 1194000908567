export const onlyNumbers = (_string) => _string?.replace(/[^0-9.,+-]+/g, "")
export const onlyUnsignedNumbers = (_string) => _string?.replace(/[^0-9]+/g, "")

export const onlyDecimal = (_string) => {
  const match = _string.match(/^[0-9]*\.?[0-9]*$/)
  if (match) return _string
  else return _string.substring(0, _string.length - 1)
}
export const ConvertMoney = (string) => {
  string = Number(string)
  string = string?.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })
  return string
}
export const isValidCnpj = (cnpj_string) => {
  cnpj_string = onlyUnsignedNumbers(cnpj_string)
  if (cnpj_string === "") return false
  // Elimina CNPJ_strings invalidos conhecidos
  if (
    cnpj_string.length !== 14 ||
    cnpj_string === "00000000000000" ||
    cnpj_string === "11111111111111" ||
    cnpj_string === "22222222222222" ||
    cnpj_string === "33333333333333" ||
    cnpj_string === "44444444444444" ||
    cnpj_string === "55555555555555" ||
    cnpj_string === "66666666666666" ||
    cnpj_string === "77777777777777" ||
    cnpj_string === "88888888888888" ||
    cnpj_string === "99999999999999"
  ) {
    return false
  }

  let tamanho = cnpj_string.length - 2
  let numeros = cnpj_string.substring(0, tamanho)
  const digitos = cnpj_string.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  let resultado = String(soma % 11 < 2 ? 0 : 11 - (soma % 11))
  if (resultado !== digitos.charAt(0)) return false
  tamanho = tamanho + 1
  numeros = cnpj_string.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  resultado = String(soma % 11 < 2 ? 0 : 11 - (soma % 11))
  if (resultado !== digitos.charAt(1)) return false
  return true
}

export const isValidCpf = (cpf_string) => {
  cpf_string = onlyNumbers(cpf_string)
  if (cpf_string === "") return false
  if (
    cpf_string.length !== 11 ||
    cpf_string === "00000000000" ||
    cpf_string === "11111111111" ||
    cpf_string === "22222222222" ||
    cpf_string === "33333333333" ||
    cpf_string === "44444444444" ||
    cpf_string === "55555555555" ||
    cpf_string === "66666666666" ||
    cpf_string === "77777777777" ||
    cpf_string === "88888888888" ||
    cpf_string === "99999999999"
  ) {
    return false
  }
  // Valida 1o digito
  let add = 0
  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf_string.charAt(i)) * (10 - i)
  }
  let rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) {
    rev = 0
  }
  if (rev !== parseInt(cpf_string.charAt(9))) {
    return false
  }

  // Valida 2o digito
  add = 0
  for (let i = 0; i < 10; i++) {
    add += parseInt(cpf_string.charAt(i)) * (11 - i)
  }
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) {
    rev = 0
  }
  if (rev !== parseInt(cpf_string.charAt(10))) {
    return false
  }
  return true
}

export const cpfMask = (cpfCnpj) => {
  if (cpfCnpj === null || cpfCnpj === undefined) return
  cpfCnpj = onlyUnsignedNumbers(cpfCnpj)

  return String(cpfCnpj)
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1")
}
export const cnpjMask = (cpfCnpj) => {
  if (cpfCnpj === null || cpfCnpj === undefined) return
  cpfCnpj = onlyUnsignedNumbers(cpfCnpj)
  return String(cpfCnpj)
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1")
}

export const phoneMaskForList = (phone) => {
  if (phone === null || phone === undefined) return
  phone = onlyUnsignedNumbers(phone)
  return String(phone)
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})\d+?$/, "$1")
}

export const cpfCnpjMask = (cpfCnpj) => {
  if (cpfCnpj === null || cpfCnpj === undefined) return
  cpfCnpj = onlyUnsignedNumbers(cpfCnpj)
  if (String(cpfCnpj).length < 11) {
    return cpfCnpj
  }
  if (String(cpfCnpj).length === 11) {
    const result = String(cpfCnpj)
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1")
    return result
  }
  if (String(cpfCnpj).length >= 12) {
    cpfCnpj = cpfCnpj.slice(-14)
    const result = String(cpfCnpj)
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1")
    return result
  }

}


export const cepMask = (cpfCnpj) => {
  if (cpfCnpj === null || cpfCnpj === undefined) return
  cpfCnpj = onlyUnsignedNumbers(cpfCnpj)
  return String(cpfCnpj)
    .replace(/\D/g, "")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1")
}

export const dateLastAccess = (data) => {
  if (data === null || data === undefined) return
  data = data?.split(" ")
  let days = data[0]?.split("-")
  days = `${days[2]} / ${days[1]} / ${days[0]}`
  return days
}

export const percentageMask = (number) => {
  return number.replace(/[^0-9,.]+/g, "").replace(/([0-9,.]+?$)/, "$1%")
}

