import api from './api'

export default {
  get: async () => {
    const response = await api
      .get('/api/list-user-notification', {})
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  acceptRoulette: async (data) => {
    const response = await api
      .post('/api/accept-roulette', data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  recuseRoulette: async (data) => {
    const response = await api
      .post('/api/recuse-roulette', data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  readNotification: async (idNotification) => {
    const response = await api
      .post(`/api/mark-notification-as-read/${idNotification}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  markAsReadAll: async () => {
    const response = await api
      .get(`/api/mark-notification-as-read-all`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
