import { useContext, createContext, useState } from 'react'
import { ModalRolloverNotification } from '../../components/ModalRolloverNotification/ModalRolloverNotification'
import { NotificationProvider } from './useNotification'

const RolloverNotificationContext = createContext({})

function RolloverNotificationProvider({ children }) {
  const [openRolloverNotification, setOpenRolloverNotification] =
    useState(false)
  const [idRolloverNotification, setIdRolloverNotification] = useState(null)

  const handleCloseRolloverNotification = () => {
    setIdRolloverNotification(null)
    setOpenRolloverNotification(false)
  }

  const handleOpenRolloverNotification = async () => {
    setOpenRolloverNotification(true)
  }

  return (
    <NotificationProvider>
      <RolloverNotificationContext.Provider
        value={{
          openRolloverNotification,
          handleOpenRolloverNotification,
          handleCloseRolloverNotification,
          idRolloverNotification,
          setIdRolloverNotification
        }}
      >
        <ModalRolloverNotification />
        {children}
      </RolloverNotificationContext.Provider>
    </NotificationProvider>
  )
}

function useRolloverNotification() {
  return useContext(RolloverNotificationContext)
}

export { RolloverNotificationProvider, useRolloverNotification }
